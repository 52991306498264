import { Avatar, Button, Card, Divider, Image } from "antd"
import React from "react"
import { displayWithLineBreaks } from "../../../utils/displayWithLineBreaks"
import moment from "moment"
import { USA_DATE_FORMAT } from "../../../utils/usaDateFormat"
import { DownloadOutlined, FileOutlined } from "@ant-design/icons"
import { isImage } from "../../../utils/isImage"
import { getFormat } from "../../../utils/getFileExtention"
import { useSelector } from "react-redux"

const downloadFile = async url => {
  const response = await fetch(url)
  const blob = await response.blob()
  const blobUrl = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobUrl
  link.setAttribute("download", url?.split("$")?.[1])
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(blobUrl)
}

const InternalNotesList = () => {
  const { internalNotes } = useSelector(state => state.getInternalNotes)
  return (
    <div
      className="internal-notes-list pr-3 pb-1 flex flex-col gap-3"
      style={{
        maxHeight: "calc( 100vh - 250px )",
        overflowY: "auto"
      }}>
      {internalNotes
        ?.sort((a, b) => new Date(b.date) - new Date(a.date))
        ?.map((note, idx) => (
          <Card key={idx} styles={{ body: { padding: 1 } }} className="border">
            <div className="p-2">
              <h4 className="text-lg mb-1 font-semibold">{note?.title}</h4>
              <p>{displayWithLineBreaks(note?.concern)}</p>
              {note?.files?.length > 0 && (
                <div className="mt-3 flex flex-wrap gap-3">
                  {note?.files.map((file, idx) => (
                    <Card key={idx} styles={{ body: { padding: "4px 10px", width: "200px" } }}>
                      <div className="flex justify-between items-center h-[40px]">
                        <div className="flex gap-2 items-center">
                          {isImage(file?.split("$")?.[1]) ? (
                            <Image width={32} src={file} />
                          ) : (
                            <Avatar shape="square" className="bg-current" icon={<FileOutlined />} />
                          )}
                          <div>
                            <p className="font-semibold mb-[-5px] truncate w-[100px]">
                              {file?.split("$")?.[1]}
                            </p>
                            <small className="uppercase text-secondary">
                              {getFormat(file?.split("$")?.[1])}
                            </small>
                          </div>
                        </div>
                        <Button
                          icon={<DownloadOutlined />}
                          size="small"
                          onClick={() => downloadFile(file)}
                        />
                      </div>
                    </Card>
                  ))}
                </div>
              )}
            </div>
            <div className="px-1">
              <Divider className="m-0" />
            </div>
            <p className="p-2 text-secondary capitalize flex flex-wrap gap-x-6 gap-y-1">
              <span>
                Created On :{" "}
                {moment(note?.date).format(`${USA_DATE_FORMAT} [at] hh:mm A`) || "-"}{" "}
                {note?.timezone && `(${note?.timezone})`}
              </span>
              <span>
                Created by :{" "}
                {note?.createdByFirstName
                  ? note?.createdByFirstName +
                    " " +
                    (note?.createdByMiddleName ? note?.createdByMiddleName : "") +
                    " " +
                    note.createdByLastName
                  : "-"}
              </span>
            </p>
          </Card>
        ))}
    </div>
  )
}

export default InternalNotesList
